import * as React from "react"
import ThemeContext from "../context/ThemeContext"

const Person = props => {
  return (
    <ThemeContext.Consumer>
      {theme => (
        <div className="font-light">
          {props.image && (
            <img
              src={theme.playful ? props.playfulImage : props.image}
              alt={props.name}
            />
          )}
          <div className="text-lg md:text-xl leading-none my-6">
            <div>{props.name}</div>
            <div className="text-right">{props.position}</div>
          </div>
          <p className="text-base mb-5">{props.bio}</p>
          {props.linkedin && (
            <a
              href={props.linkedin}
              target="_blank"
              className="border-deeptech-silver font-normal border rounded-full uppercase text-[0.8125rem] pt-[5px] pb-[4px] leading-[0.7] inline-block px-1.5 tracking-widest"
              rel="noreferrer"
            >
              Linked In
            </a>
          )}
        </div>
      )}
    </ThemeContext.Consumer>
  )
}

export default Person
