import React, { useEffect } from "react"
import { useAnimation, motion } from "framer-motion"
import ThemeContext from "../context/ThemeContext"
import { StaticImage } from "gatsby-plugin-image"
import { useInView } from "react-intersection-observer"

const Queen = props => {
  const comeIntoView = useAnimation()
  const [ref, inView] = useInView()

  const variants = {
    visible: {
      x: "-23%",
      transition: {
        delay: 0.75,
        type: "spring",
        stiffness: 150,
        duration: 3,
        mass: 0.5,
      },
    },
    smvisible: {
      x: "-12%",
      transition: {
        delay: 0.75,
        type: "spring",
        stiffness: 150,
        duration: 3,
        mass: 0.5,
      },
    },
    hidden: { x: "-100%" },
  }

  useEffect(() => {
    const animateQueen = () => {
      const body = document.querySelector("body")
      const siteWidth = body.offsetWidth

      if (inView) {
        if (siteWidth > 767) {
          comeIntoView.start("visible")
        } else {
          comeIntoView.start("smvisible")
        }
      }
    }
    animateQueen()
    window.addEventListener("resize", animateQueen)
    return () => window.removeEventListener("resize", animateQueen)
  }, [comeIntoView, inView])

  return (
    <div {...props} ref={ref}>
      <ThemeContext.Consumer>
        {theme => (
          <div className="overflow-hidden relative -z-10x">
            <motion.div
              animate={comeIntoView}
              variants={variants}
              initial="hidden"
            >
              <StaticImage
                src="../images/queen.png"
                alt="Queen"
                layout="fullWidth"
                placeholder={false}
                className="w-full h-auto"
              />
            </motion.div>
          </div>
        )}
      </ThemeContext.Consumer>
    </div>
  )
}

export default Queen
