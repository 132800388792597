import React, { useEffect } from "react"
import { useAnimation, motion } from "framer-motion"
import ThemeContext from "../context/ThemeContext"
import { StaticImage } from "gatsby-plugin-image"
import { useInView } from "react-intersection-observer"

const Man = props => {
  const comeIntoView = useAnimation()
  const [ref, inView] = useInView()

  const variants = {
    visible: {
      y: "15%",
      transition: {
        delay: 0.75,
        type: "spring",
        stiffness: 100,
        duration: 3,
        mass: 0.5,
      },
    },
    hidden: { y: "100%" },
  }

  useEffect(() => {
    if (inView) {
      comeIntoView.start("visible")
    }
  }, [comeIntoView, inView])

  return (
    <div {...props}>
      <ThemeContext.Consumer>
        {theme => (
          <div className="overflow-hidden relative -z-10 top-px" ref={ref}>
            <motion.div
              animate={comeIntoView}
              variants={variants}
              initial="hidden"
            >
              <StaticImage
                src="../images/man.png"
                alt="Man"
                placeholder={false}
                className="max-w-[14rem] md:max-w-[20rem] w-full h-auto relative md:left-[10%]"
              />
            </motion.div>
          </div>
        )}
      </ThemeContext.Consumer>
    </div>
  )
}

export default Man
