import { Link } from "gatsby"
import React from "react"

const ButtonLink = ({ text, link }) => {
  const isExternal = link.startsWith("http")
  const linkClass = `inline-block border border-current rounded-full py-2 px-6 md:py-3 md:px-8 text-lg md:text-xl font-light`

  if (isExternal) {
    return (
      <a href={link} target="_blank" rel="noreferrer" className={linkClass}>
        {text}
      </a>
    )
  } else {
    return (
      <Link to={link} className={linkClass}>
        {text}
      </Link>
    )
  }
}

export default ButtonLink
