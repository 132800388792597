import * as React from "react"

const Globe = props => (
  <svg
    width="46"
    height="46"
    viewBox="0 0 46 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="23" cy="23" r="22" stroke="currentColor" strokeWidth="2" />
    <path
      d="M33.7348 23C33.7348 29.2269 32.4449 34.8147 30.407 38.809C28.3414 42.8575 25.6683 45 23.0001 45C20.3318 45 17.6587 42.8575 15.5932 38.809C13.5552 34.8147 12.2654 29.2269 12.2654 23C12.2654 16.7731 13.5552 11.1853 15.5932 7.19101C17.6587 3.14249 20.3318 1 23.0001 1C25.6683 1 28.3414 3.14249 30.407 7.19101C32.4449 11.1853 33.7348 16.7731 33.7348 23Z"
      stroke="currentColor"
      strokeWidth="2"
    />
    <line
      x1="22.5918"
      y1="0.938782"
      x2="22.5918"
      y2="45.0612"
      stroke="currentColor"
      strokeWidth="2"
    />
    <line
      x1="2.81641"
      y1="16.8367"
      x2="43.1838"
      y2="16.8367"
      stroke="currentColor"
      strokeWidth="2"
    />
    <line
      x1="2.81641"
      y1="31.8571"
      x2="43.1838"
      y2="31.8571"
      stroke="currentColor"
      strokeWidth="2"
    />
  </svg>
)

export default Globe
