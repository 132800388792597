import React from "react"
import Container from "../../components/Container"
import BlogPreview from "../../components/BlogPreview"
import ButtonLink from "../../components/ButtonLink"
import Man from "../../components/Man"
import ThemeContext from "../../context/ThemeContext"
import SectionBorder from "../../components/SectionBorder"

const FromTheBlog = props => {
  return (
    <ThemeContext.Consumer>
      {theme => (
        <div
          className="pt-16 lg:pt-36"
          data-sal="slide-up"
          data-sal-delay={100}
          data-sal-duration="500"
          data-sal-easing="ease-out"
        >
          <SectionBorder />
          <Container>
            <div className="grid grid-cols-8 gap-8 pt-8">
              <div className="col-span-8 md:col-span-2">
                <h2 className="text-[4.5rem] md:text-7xl font-light leading-[0.9] mb-5 md:mb-12">
                  From the <br /> blog
                </h2>
                <ButtonLink link="/blog" text="View all posts" />
              </div>
              <div className="col-span-8 md:col-span-6 mt-6 md:pt-24">
                <BlogPreview />
              </div>
            </div>

            <div className="grid grid-cols-4"></div>
            {theme.playful && <Man />}
          </Container>
        </div>
      )}
    </ThemeContext.Consumer>
  )
}

export default FromTheBlog
