import React from "react"

const PortfolioBlock = ({ title, image, maxWidth, idx, link }) => {
  return (
    <a
      href={link}
      target="_blank"
      rel="noreferrer"
      className="border border-deeptech-silver py-8 flex items-center justify-center px-4"
      data-sal="flip-up"
      data-sal-duration="500"
      data-sal-easing="ease-out"
      style={{ transitionDelay: `${idx * 100 + 250}ms` }}
    >
      <img
        src={image}
        alt={title}
        className="inline-block w-full h-auto"
        style={{ maxWidth: `${maxWidth / 16}rem` }}
      />
    </a>
  )
}

export default PortfolioBlock
