import React from "react"
import ArticleCard from "./ArticleCard"
import { useStaticQuery, graphql } from "gatsby"

const BlogPreview = () => {
  const { allStrapiArticle } = useStaticQuery(graphql`
    query {
      allStrapiArticle(limit: 3, sort: { fields: createdAt, order: DESC }) {
        nodes {
          ...ArticleCard
        }
      }
    }
  `)
  return (
    <div>
      {allStrapiArticle.nodes.map((article, idx) => (
        <ArticleCard key={idx} article={article} />
      ))}
    </div>
  )
}

export default BlogPreview
