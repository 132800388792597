import React, { useEffect } from "react"
import { StaticImage } from "gatsby-plugin-image"
import ThemeContext from "../context/ThemeContext"
import { useAnimation, motion } from "framer-motion"
import { useInView } from "react-intersection-observer"

const Hi = props => {
  const comeIntoView = useAnimation()
  const [ref, inView] = useInView()

  const variants = {
    visible: {
      scale: "100%",
      transition: {
        delay: 1,
        type: "spring",
        stiffness: 150,
        duration: 3,
        mass: 0.5,
      },
    },
    hidden: { scale: "0" },
  }

  useEffect(() => {
    if (inView) {
      comeIntoView.start("visible")
    }
  }, [comeIntoView, inView])

  return (
    <ThemeContext.Consumer>
      {theme => (
        <div {...props} ref={ref}>
          <motion.div
            animate={comeIntoView}
            variants={variants}
            initial="hidden"
          >
            <svg
              width="490"
              height="306"
              viewBox="0 0 490 306"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="w-full h-auto"
            >
              <path
                d="M363.592 0H126.408C56.5921 0 0 56.965 0 127.24C0 197.516 56.5921 254.481 126.408 254.481H363.592C433.408 254.481 490 197.516 490 127.24C490.007 56.965 433.408 0 363.592 0ZM419.21 37.5828L407.397 153.705H349.609L337.796 37.5828H419.21ZM243.8 99.044H203.089V150.264H243.8V211.725H162.386V150.264H192.915V129.775H121.675V150.264H152.204V211.725H70.7974V150.264H111.508V99.044H70.7974V37.5828H152.212V99.044H121.682V119.534H192.923V99.044H162.393V37.5828H243.808V99.044H243.8ZM285.707 211.762H265.352V37.5828H285.707V211.762ZM316.244 211.762H295.889V37.5828H316.244V211.762ZM427.834 182.734C402.553 182.734 381.711 193.874 378.839 208.24C378.765 208.619 378.241 208.619 378.167 208.24C375.295 193.882 354.452 182.734 329.172 182.734C354.452 182.734 375.295 171.593 378.167 157.228C378.241 156.849 378.765 156.849 378.839 157.228C381.711 171.586 402.553 182.734 427.834 182.734Z"
                fill="currentColor"
              />
              <path
                d="M194 254.481H244.896L194 305.377L194 254.481Z"
                fill="currentColor"
              />
            </svg>
          </motion.div>
          <StaticImage
            src="../images/cryptokitty.png"
            alt="Crypto Kitty"
            placeholder="blurred"
            layout="fixed"
            width={156}
            height={131}
            className={`relative -mt-5 ml-6 hidden md:block invisible lg:visible ${
              !theme.playful ? "!invisible" : ""
            }`}
          />
        </div>
      )}
    </ThemeContext.Consumer>
  )
}

export default Hi
