import React from "react"
import Container from "../../components/Container"
import Person from "../../components/Person"
import Hi from "../../components/Hi"
import ThemeContext from "../../context/ThemeContext"
import Dani from "../../images/dani.jpg"
import DaniPlayful from "../../images/dani-playful.gif"
import Alex from "../../images/alex.jpg"
import AlexPlayful from "../../images/alex-playful.gif"
import Florian from "../../images/florian.jpg"
import FlorianPlayful from "../../images/florian-playful.gif"
import Francisco from "../../images/francisco.jpg"
import FranciscoPlayful from "../../images/francisco-playful.gif"
import Lex from "../../images/lex.jpg"
import LexPlayful from "../../images/lex-playful.gif"
import Fleur from "../../images/fleur.jpg"
import FleurPlayful from "../../images/fleur-playful.gif"

const MeetTheTeam = () => {
  return (
    <ThemeContext.Consumer>
      {theme => (
        <div className="pt-16 lg:pt-36">
          <div className="border-t pt-6"></div>
          <Container>
            <div>
              <div className="grid grid-cols-8 gap-5 mb-8 md:mb-0">
                <div
                  className="col-span-8 md:col-span-5 lg:col-span-4 order-2 md:order-1 gap-5"
                  data-sal="slide-up"
                  data-sal-delay={100}
                  data-sal-duration="500"
                  data-sal-easing="ease-out"
                >
                  <h2 className="text-6xl lg:text-7xl font-light leading-[0.9] mb-5">
                    Meet the
                    <br />
                    team
                  </h2>

                  <p className="text-lg lg:text-xl font-light">
                    With backgrounds in economics, finance and software
                    development, we understand the tantalising possibilities of
                    Web3 and put our money where our beliefs are. Discover the
                    team rolling their sleeves up to help make the internet
                    'ours to own'.
                  </p>
                </div>
                <div className="col-span-8 md:col-span-3 lg:col-span-4 flex justify-end order-1 md:order-2">
                  <Hi
                    className={`w-full h-auto max-w-[17rem] md:max-w-[30rem] ${
                      !theme.playful ? "invisible" : ""
                    }`}
                  />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-8 mb-20 gap-5">
              <div className="col-span-8 md:col-span-2">
                <h2 className="text-[1.75rem] mb-4 md:text-3xl font-extralight leading-none">
                  Core team
                </h2>
              </div>
              <div className="col-span-8 md:col-span-6">
                <div className="grid grid-cols-2 md:grid-cols-3 gap-x-4 gap-y-10">
                  <Person
                    name="Alexander Christen"
                    position="Founder"
                    image={Alex}
                    playfulImage={AlexPlayful}
                    bio="Tech-savvy investor with an eye and a passion for technologies reshaping the world
we live in. CEO of FiveT Fintech with previous positions at Partners Group and SIX
Swiss Exchange."
                    linkedin="https://www.linkedin.com/in/alexchristen/"
                    idx={1}
                  />
                  <Person
                    name="Daniel Curiger"
                    position="Managing Partner"
                    image={Dani}
                    playfulImage={DaniPlayful}
                    bio="Passionate about emerging technologies, behavioural economics, and their impact
on society. Formerly COO at Fixposition and various roles at Goldman Sachs and
UBS."
                    linkedin="https://www.linkedin.com/in/danielcuriger/"
                    idx={2}
                  />
                  <Person
                    name="Florian Bitterli"
                    position="Crypto Research Analyst"
                    image={Florian}
                    playfulImage={FlorianPlayful}
                    bio="Blockchain enthusiast and excited about decentralization and disruptive technologies. Bridging DeFi and Fintech with experience in traditional Venture Capital."
                    linkedin="https://www.linkedin.com/in/florian-bitterli-77a885158/"
                    idx={3}
                  />
                </div>
              </div>
            </div>

            <div className="grid grid-cols-8 gap-5">
              <div className="col-span-8 md:col-span-2">
                <h2 className="text-[1.75rem] mb-4 md:text-3xl font-extralight leading-none">
                  Advisory board
                </h2>
              </div>
              <div className="col-span-8 md:col-span-6">
                <div className="grid grid-cols-2 md:grid-cols-3 gap-x-4 gap-y-10">
                  <Person
                    name="Francisco Fernandez"
                    bio="Serial entrepreneur and visionary tech-investor with a passion for disrupting
business models, music, art and cars. Founded Avaloq in 2000 - a financial
technology and services provider for banking - and sold to NEC for over $2bn in
2020. Founding partner of Crowdhouse, Racing Unleashed, Innoterra, MYARTBANK
with board positions at Avaloq, Metaco, Carvolution, Utopia Music and Innoplexus."
                    image={Francisco}
                    linkedin="https://www.linkedin.com/in/francisco-fernandez-977533/"
                    playfulImage={FranciscoPlayful}
                    idx={4}
                  />
                  <Person
                    name="Fleur Heyns"
                    bio="Serial FinTech entrepreneur building next generation platforms to optimise for impact
and financial returns. Founder and CEO of Proof of Impact with previous positions at
Osmosis Investment Management and J.P Morgan. Co-founded Global Trader in
2000 which was successfully sold in 2007. Chairwoman of Pyrpose."
                    linkedin="https://www.linkedin.com/in/fleur-heyns-9a8a9436/"
                    image={Fleur}
                    playfulImage={FleurPlayful}
                    idx={5}
                  />
                  <Person
                    name="Lex Fenwick"
                    linkedin="https://www.linkedin.com/in/lexfenwick/"
                    image={Lex}
                    bio="Salesperson at heart and believer in transparency, meritocracy, luck and the equality
of all. Former CEO of Bloomberg for 7 years and Dow Jones &amp; Company for 2 years.
Founded Bloomberg Ventures in 2008 and currently involved with HENI and board
positions at MoMA and PS1, amongst others."
                    playfulImage={LexPlayful}
                    idx={6}
                  />
                </div>
              </div>
            </div>
          </Container>
        </div>
      )}
    </ThemeContext.Consumer>
  )
}

export default MeetTheTeam
