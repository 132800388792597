import React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import FullScreenHeight from "../components/FullScreenHeight"
import Container from "../components/Container"
import Arrow from "../components/Arrow"
import Queen from "../components/Queen"
//import Bullet from "../components/Bullet"
import Globe from "../components/Globe"
import ButtonLink from "../components/ButtonLink"
import ManifestoCta from "../components/Home/ManifestoCta"
import MeetTheTeam from "../components/Home/MeetTheTeam"
import FromTheBlog from "../components/Home/FromTheBlog"
import ethereumLogo from "../images/logos/ethereum-logo.svg"
import convexLogo from "../images/logos/convex-logo.svg"
import rocketpoolLogo from "../images/logos/rocketpool-logo.svg"
import renLogo from "../images/logos/ren-logo.svg"
import poktLogo from "../images/logos/pokt-logo.svg"
import proofLogo from "../images/logos/proof-logo.svg"
import limewireLogo from "../images/logos/limewire-logo.svg"
import looksrareLogo from "../images/logos/looksrare-logo.svg"
import ThemeContext from "../context/ThemeContext"
import SectionBorder from "../components/SectionBorder"
import PortfolioBlock from "../components/Home/PortfolioBlock"

const IndexPage = ({ data }) => {
  return (
    <ThemeContext.Consumer>
      {theme => (
        <Layout>
          <Seo
            title="Home"
            description="DeepTech Ventures are investment pioneers supporting Web3 visionaries, protocols, and networks across the entire tech stack. Taking a hands-on approach, we work toward a future where users can finally call the internet 'ours to own.'"
          />

          <FullScreenHeight>
            <Container className="h-full pb-8">
              <div
                className="flex flex-col md:grid md:grid-cols-2 h-full justify-between"
                data-sal="slide-up"
                data-sal-delay={100}
                data-sal-duration="500"
                data-sal-easing="ease-out"
              >
                <div className="relative order-2 md:order-1 md:col-span-1 flex items-end justify-between">
                  <h2 className="text-[3.8125rem] md:text-[15vw] lg:text-8xl font-light leading-[0.75]">
                    <span
                      className={`${
                        theme.playful ? "font-mono tracking-tighter" : ""
                      }`}
                    >
                      Ours
                    </span>
                    <br />
                    to own
                  </h2>
                  <ManifestoCta
                    className="md:hidden -translate-y-14"
                    size="small"
                  />
                  <AnchorLink to="#intro">
                    <Arrow className="rotate-90 right-0 bottom-2 absolute md:hidden" />
                  </AnchorLink>
                </div>
                <div className="order-1 flex md:order-2 md:col-span-1 relative md:flex md:justify-center md:flex-col md:pb-16 md:pt-8">
                  <div className="md:w-full pt-6 md:pt-0">
                    <div className="max-w-[30rem] md:max-w-none text-xl md:text-2xl lg:text-2xl leading-tight font-light mb-16 md:mb-6">
                      DeepTech Ventures are investment pioneers supporting Web3
                      visionaries, protocols, and networks across the entire
                      tech stack. Taking a hands-on approach, we work toward a
                      future where users can finally call the internet 'ours to
                      own.'
                    </div>
                    <ManifestoCta className="hidden md:block" />
                  </div>
                  <AnchorLink to="#intro">
                    <Arrow className="hidden md:block rotate-90 absolute bottom-3 left-0" />
                  </AnchorLink>
                </div>
              </div>
            </Container>
          </FullScreenHeight>

          <div className="relative pt-10 md:pt-36" id="intro">
            {theme.playful && (
              <Queen className="absolute top-[25%] left-0 w-[30%] lg:w-2/5 -translate-x-[1.5625rem] lg:translate-y-16 max-w-[18rem]" />
            )}
            <Container>
              <div className="grid grid-cols-8">
                <div className="col-span-2"></div>

                <div
                  className="col-span-6"
                  data-sal="slide-up"
                  data-sal-delay={100}
                  data-sal-duration="500"
                  data-sal-easing="ease-out"
                >
                  <div>
                    <div className="relative text-2xl md:text-4xl lg:text-[4rem] leading-none font-light mb-12 md:mb-20 max-w-[25rem] md:max-w-none">
                      Since 2017, we've been at the forefront of realising the
                      vision of an internet{" "}
                      <Globe
                        className={`${
                          theme.playful ? "inline" : "hidden"
                        } w-[1.5rem] h-[1.5rem] md:w-[2rem] md:h-[2rem] lg:w-auto lg:h-auto`}
                      />{" "}
                      where users regain control of their data.
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="grid grid-cols-8 gap-4 font-light"
                data-sal="slide-up"
                data-sal-delay={100}
                data-sal-duration="500"
                data-sal-easing="ease-out"
              >
                <div className="col-span-2 md:col-span-4"></div>
                <div className="col-span-6 md:col-span-4">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
                    <div>
                      <div className="uppercase text-sm tracking-widest mb-3 md:mb-6">
                        We invest in:
                      </div>
                      <ul className="text-lg md:text-xl leading-tight">
                        <li className="mb-4">Equity, Tokens, SAFE, SAFT..</li>
                        <li className="mb-4">
                          ..Strengthening the Web3 technology stack
                        </li>
                        <li>
                          ..Applications creating new markets or disrupting
                          business models
                        </li>
                      </ul>
                    </div>
                    <div>
                      <div className="uppercase text-sm tracking-widest mb-3 md:mb-6">
                        We participate through:
                      </div>
                      <ul className="text-lg md:text-xl leading-tight">
                        <li className="mb-4">Governance voting</li>
                        <li className="mb-4">Providing liquidity</li>
                        <li className="mb-4">Keeper operations</li>
                        <li>Securing networks by operating own nodes</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </Container>
          </div>

          <MeetTheTeam />

          <div className="pt-16 lg:pt-36">
            <SectionBorder />
            <Container className="pt-8">
              <div
                data-sal="slide-up"
                data-sal-delay={100}
                data-sal-duration="500"
                data-sal-easing="ease-out"
              >
                <div className="grid grid-cols-8 mb-8 md:mb-0 gap-5">
                  <div className="col-span-8 md:col-span-4 md:pb-36">
                    <h2 className="text-6xl lg:text-7xl font-light leading-[0.9]">
                      Our
                      <br />
                      portfolio
                    </h2>
                  </div>
                  <div className="col-span-8 md:col-span-4">
                    <div className="max-w-[32rem] md:translate-y-16 lg:translate-y-24">
                      <p className="text-lg lg:text-xl font-light mb-6">
                        We're proud to say, companies in our investment
                        portfolio have already given the world a glimpse of
                        what's possible.
                      </p>
                      <ButtonLink text="Who we invest in" link="/portfolio" />
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-4"></div>

                <div className="uppercase mb-5 tracking-widest font-light">
                  Featured Investments
                </div>
                <div className="grid grid-cols-2 md:grid-cols-4 text-center gap-4">
                  <PortfolioBlock
                    image={ethereumLogo}
                    title="Ethereum"
                    maxWidth={170}
                    idx={1}
                    link="https://ethereum.org/en/"
                  />
                  <PortfolioBlock
                    image={convexLogo}
                    title="Convex"
                    maxWidth={130}
                    idx={2}
                    link="https://www.convexfinance.com/"
                  />
                  <PortfolioBlock
                    image={rocketpoolLogo}
                    title="RocketPool"
                    maxWidth={180}
                    idx={3}
                    link="https://rocketpool.net/"
                  />
                  <PortfolioBlock
                    image={renLogo}
                    title="Ren"
                    maxWidth={115}
                    idx={4}
                    link="https://renproject.io/"
                  />
                  <PortfolioBlock
                    image={poktLogo}
                    title="POKT}"
                    maxWidth={130}
                    idx={5}
                    link="https://www.pokt.network/"
                  />
                  <PortfolioBlock
                    image={proofLogo}
                    title="Proof"
                    maxWidth={128}
                    idx={6}
                    link="https://collective.proof.xyz/"
                  />
                  <PortfolioBlock
                    image={limewireLogo}
                    title="LimeWire Logo"
                    maxWidth={155}
                    idx={7}
                    link="https://limewire.com/"
                  />
                  <PortfolioBlock
                    image={looksrareLogo}
                    title="Looks Rare"
                    maxWidth={184}
                    idx={8}
                    link="https://looksrare.org/"
                  />
                </div>
              </div>
            </Container>
          </div>
          <FromTheBlog />
        </Layout>
      )}
    </ThemeContext.Consumer>
  )
}

export default IndexPage
