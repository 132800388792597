import React, { useLayoutEffect, useRef } from "react"

const FullScreenHeight = props => {
  const homeScreen = useRef(null)

  useLayoutEffect(() => {
    const onResize = () => {
      const body = document.querySelector("body")
      const siteHeader = document.querySelector("header")
      const siteHeaderHeight = siteHeader.offsetHeight
      const siteWidth = body.offsetWidth
      const screenHeightRemainder = window.innerHeight - siteHeaderHeight

      if (
        siteWidth > 767 &&
        screenHeightRemainder > 390 &&
        screenHeightRemainder < 850
      ) {
        homeScreen.current.style.height = screenHeightRemainder + "px"
      } else {
        homeScreen.current.style.height = "auto"
      }
    }
    onResize()
    window.addEventListener("resize", onResize)
    return () => window.removeEventListener("resize", onResize)
  }, [homeScreen])

  return <div ref={homeScreen}>{props.children}</div>
}

export default FullScreenHeight
