import React, { useEffect, useRef } from "react"
import { motion } from "framer-motion"

const ReadTheManifesto = props => {
  const leftEye = useRef()
  const rightEye = useRef()

  useEffect(() => {
    const onMouseMove = event => {
      if (event && leftEye.current) {
        const rect = leftEye.current.getBoundingClientRect()
        const width = rect.width
        const height = rect.height
        const top = rect.top + window.scrollY
        const left = rect.left + window.scrollX
        const x = left + width / 2
        const y = top + height / 2
        const rad = Math.atan2(event.clientX - x, event.clientY - y)
        const rot = rad * (180 / Math.PI) * -1 + 180
        leftEye.current.style.transform = "rotate(" + rot + "deg)"
        rightEye.current.style.transform = "rotate(" + rot + "deg)"
      }
    }
    window.addEventListener("mousemove", onMouseMove)
    return () => {
      window.removeEventListener("mousemove", onMouseMove)
    }
  }, [])

  return (
    <svg
      width="121"
      height="120"
      viewBox="0 0 121 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`w-full h-auto ${props.className ? props.className : ""}`}
    >
      <g fill="currentColor">
        <g className="transform-fill-box origin-center" ref={leftEye}>
          <path d="M47.5568 68.4309C53.5634 69.341 59.1704 65.2095 60.0805 59.2029C60.9906 53.1963 56.859 47.5893 50.8524 46.6792C44.8459 45.7691 39.2388 49.9007 38.3287 55.9072C37.4187 61.9138 41.5502 67.5209 47.5568 68.4309Z" />
          <path
            className="fill-deeptech-purple"
            d="M46.5587 57.5075C48.9336 57.8673 51.1506 56.2337 51.5104 53.8588C51.8703 51.4838 50.2367 49.2669 47.8618 48.907C45.4868 48.5472 43.2698 50.1808 42.91 52.5557C42.5502 54.9307 44.1837 57.1477 46.5587 57.5075Z"
          />
        </g>
        <g className="transform-fill-box origin-center" ref={rightEye}>
          <path d="M71.286 72.0262C77.2926 72.9362 82.8997 68.8047 83.8097 62.7981C84.7198 56.7915 80.5883 51.1845 74.5817 50.2744C68.5751 49.3643 62.9681 53.4959 62.058 59.5025C61.1479 65.509 65.2795 71.1161 71.286 72.0262Z" />
          <path
            className="fill-deeptech-purple"
            d="M70.2845 61.1022C72.6595 61.4621 74.8764 59.8285 75.2363 57.4535C75.5961 55.0786 73.9625 52.8616 71.5876 52.5018C69.2126 52.1419 66.9957 53.7755 66.6358 56.1505C66.276 58.5254 67.9096 60.7424 70.2845 61.1022Z"
          />
        </g>
        <motion.g
          animate={{ rotate: props.direction === "backwards" ? -360 : 360 }}
          style={{ originX: "center", originY: "center" }}
          transition={{
            duration: 10,
            repeat: Infinity,
            ease: "linear",
          }}
        >
          <g clipPath="url(#readTheManifesto)">
            <path d="M16.8437 43.1126C16.7683 43.3663 16.6757 43.6113 16.5803 43.8743L15.9377 45.6331L20.1265 47.168L19.6119 48.57L9.65188 44.915L10.8091 41.7543C11.7014 39.3243 12.8104 37.7842 15.2 38.6581C16.7774 39.2362 17.2558 40.2707 17.1384 41.6156L22.41 40.9283L21.8337 42.4935L16.8377 43.1117L16.8437 43.1126ZM11.3458 43.9507L14.7514 45.2005L15.4355 43.3309C15.9704 41.8765 16.2383 40.6776 14.6953 40.117C13.1593 39.5513 12.5587 40.6258 12.0229 42.0862L11.3388 43.9558L11.3458 43.9507Z" />
            <path d="M17.6598 28.6095L22.244 23.0574L23.2355 23.8798L19.6026 28.281L22.0849 30.3344L25.5052 26.1969L26.4967 27.0193L23.0764 31.1568L25.7942 33.4061L29.4271 29.0049L30.4186 29.8273L25.8344 35.3793L17.6579 28.6215L17.6598 28.6095Z" />
            <path d="M32.1744 15.1459L33.6487 14.247L42.5803 21.2302L41.2073 22.0705L39.2992 20.5419L35.1354 23.0745L35.6308 25.4619L34.3333 26.252L32.1813 15.1408L32.1744 15.1459ZM34.8612 21.7503L38.2371 19.6965L33.6596 16.0061L34.8612 21.7503Z" />
            <path d="M49.01 8.21885C52.3145 7.57256 54.9223 8.34999 55.6975 12.3091C56.4761 16.2873 54.344 17.9868 51.0404 18.6271L48.1997 19.1833L46.1693 8.77508L49.01 8.21885ZM49.2099 9.46392L47.8755 9.72422L49.4223 17.6666L50.7567 17.4063C52.9107 16.9865 54.8932 16.1523 54.2033 12.6069C53.5115 9.07354 51.363 9.05016 49.2089 9.46994L49.2099 9.46392Z" />
            <path d="M77.0681 11.1443L73.8239 9.96827L74.2661 8.75882L82.1565 11.6254L81.7203 12.8358L78.4761 11.6598L75.2986 20.4218L73.8957 19.9133L77.0732 11.1512L77.0681 11.1443Z" />
            <path d="M97.1081 21.8947L90.3229 30.0485L89.179 29.0921L92.2619 25.3906L87.9739 21.8242L84.891 25.5257L83.7411 24.5683L90.5324 16.4154L91.6762 17.3719L88.7893 20.8378L93.0713 24.4033L95.9582 20.9374L97.102 21.8938L97.1081 21.8947Z" />
            <path d="M104.007 30.3274L107.634 36.55L106.523 37.2019L103.651 32.2714L100.871 33.8913L103.578 38.5316L102.468 39.1835L99.7609 34.5432L96.7168 36.3204L99.5894 41.251L98.4793 41.9029L94.8528 35.6803L104.018 30.3353L104.007 30.3274Z" />
            <path d="M102.157 60.7312L111.536 58.2489L102.298 57.8051L102.37 56.3114L112.968 56.8194L112.848 59.3172L103.888 61.5978L112.59 64.7231L112.471 67.2209L101.874 66.7129L101.946 65.2192L111.184 65.6631L102.086 62.2991L102.165 60.7201L102.157 60.7312Z" />
            <path d="M108.464 80.439L107.75 82.0144L96.436 81.2744L97.102 79.809L99.5397 79.9995L101.55 75.5622L99.7969 73.8659L100.426 72.4813L108.469 80.446L108.464 80.439ZM102.525 76.493L100.894 80.0938L106.755 80.5625L102.525 76.493Z" />
            <path d="M88.4645 90.6235L98.308 93.5825L91.8961 87.1846L92.9501 86.1295L100.46 93.6249L98.911 95.1785L89.0977 92.224L95.4882 98.6002L94.4342 99.6553L86.9245 92.1599L88.4655 90.6174L88.4645 90.6235Z" />
            <path d="M79.4324 96.8397L80.7481 96.1326L85.777 105.472L84.4613 106.179L79.4324 96.8397Z" />
            <path d="M75.0376 110.055L68.3296 111.634L68.0324 110.381L73.2889 109.142L72.5489 106.008L67.5931 107.175L67.2959 105.922L72.2517 104.755L71.1489 100.068L72.6004 99.7265L75.0315 110.054L75.0376 110.055Z" />
            <path d="M58.8809 112.176L51.7062 111.545L51.8206 110.261L57.5038 110.765L57.7861 107.558L52.4373 107.087L52.5516 105.803L57.9005 106.274L58.2105 102.763L52.5273 102.26L52.6416 100.976L59.8163 101.606L58.8809 112.176Z" />
            <path d="M38.8784 107.77C37.1389 107.001 35.9192 105.527 36.3753 103.494L37.6982 104.083C37.5365 105.353 38.4665 106.215 39.4432 106.647C40.1949 106.977 41.6491 107.228 42.1343 106.142C42.5166 105.288 42.0236 104.553 41.2138 103.955L40.2053 103.205C38.8616 102.224 37.333 100.876 38.1963 98.9228C39.0393 97.0218 41.2494 96.8017 43.1737 97.6543C45.4621 98.667 46.3541 100.553 45.7469 102.607L44.406 102.016C44.7433 100.562 44.1155 99.4558 42.6311 98.7931C41.1983 98.1567 40.0712 98.4731 39.6502 99.4206C39.2001 100.438 39.8787 101.25 40.9773 102.057L42.0665 102.845C43.2584 103.729 44.2724 105.054 43.5525 106.672C42.7368 108.515 40.4384 108.463 38.8733 107.763L38.8784 107.77Z" />
            <path d="M26.2496 97.9206L28.8011 100.25L27.9351 101.204L21.7326 95.5405L22.6037 94.5933L25.1553 96.9223L31.439 90.043L32.5445 91.0492L26.2547 97.9276L26.2496 97.9206Z" />
            <path d="M23.5343 79.4168C24.9895 82.1038 23.8838 84.8839 21.0685 86.4121C18.2532 87.9403 15.3283 87.343 13.8741 84.6499C12.419 81.9629 13.5117 79.187 16.327 77.6588C19.1422 76.1306 22.0801 76.7237 23.5352 79.4108L23.5343 79.4168ZM22.4264 80.0136C21.5062 78.314 19.4541 77.6948 17.0485 79.0014C14.643 80.3081 14.0557 82.3527 14.976 84.0522C15.8963 85.7517 17.9354 86.3752 20.34 85.0746C22.7456 83.7679 23.3467 81.7131 22.4255 80.0196L22.4264 80.0136Z" />
            <path d="M19.5008 69.5314L17.8602 69.8995L17.4922 68.2589L19.1327 67.8909L19.5008 69.5314Z" />
          </g>
        </motion.g>
      </g>
      <defs>
        <clipPath id="readTheManifesto">
          <rect
            width="105"
            height="105"
            fill="white"
            transform="translate(16.457) rotate(8.61547)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default ReadTheManifesto
