import React from "react"
import ReadTheManifesto from "../ReadTheManifesto"
import ButtonLink from "../../components/ButtonLink"
import ThemeContext from "../../context/ThemeContext"

const ManifestoCta = props => {
  return (
    <ThemeContext.Consumer>
      {theme => (
        <div {...props}>
          {theme.playful ? (
            <a href="/manifesto" className="inline-block">
              <ReadTheManifesto
                className={`${
                  props.size === "small" ? "w-[5rem] h-[5rem]" : ""
                }`}
              />
            </a>
          ) : (
            <div className="flex items-center">
              <ButtonLink link="/manifesto" text="Read manifesto" />
            </div>
          )}
        </div>
      )}
    </ThemeContext.Consumer>
  )
}

export default ManifestoCta
